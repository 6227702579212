
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import React from 'react';
import WebApi from 'shared/services';
import { PageContext } from 'shared/lib/toolkit/store/pagecontext';
import { getSession } from 'next-auth/react';
import Head from 'next/head';
import { Dashboard } from 'shared/ui/Layout/Dashboard';
import { MainPage } from 'models/Main';
import { Category } from 'services/interfaces/Category';
import { Measure } from 'services/interfaces/Measure';
import { MachineRoom } from 'services/interfaces/MachineRoom';
import { CarryingCapacity } from 'services/interfaces/CarryingCapacity';
import { LiftingSpeed } from 'services/interfaces/LiftingSpeed';
import { Company } from 'services/interfaces/Company';
const HomePage = ({ categories, company, measureUnit, machineRoom, carryingCapacity, liftingSpeed, }: {
    categories: Category[];
    company: Company[] | [
    ];
    measureUnit: Measure;
    machineRoom: MachineRoom;
    carryingCapacity: CarryingCapacity;
    liftingSpeed: LiftingSpeed;
}): JSX.Element => {
    return (<>
      <Head>
        <title>Главная</title>
      </Head>
      <Dashboard>
        <PageContext.Provider value={{
            categories,
            company,
            measureUnit,
            machineRoom,
            carryingCapacity,
            liftingSpeed,
        }}>
          <MainPage categories={categories} company={company}/>
        </PageContext.Provider>
      </Dashboard>
    </>);
};
const getServerSideProps: GetServerSideProps = async (context: GetServerSidePropsContext) => {
    const session = await getSession(context);
    const categories = await WebApi(await getSession(context)).Categories.getList({
        countAdvert: true,
        countOrganization: true,
        advertStatus: 'published',
        organizationType: 'plant',
    });
    const company = await WebApi(session).Company.getList({
        userId: session?.user?.id,
        organizationStatus: 'verified',
    });
    const measureUnit = await WebApi(session).Dictionary.getOne({
        dict: 'measureUnit',
    });
    const carryingCapacity = await WebApi(session).Dictionary.getOne({
        dict: 'carryingCapacity',
    });
    const machineRoom = await WebApi(session).Dictionary.getOne({
        dict: 'machineRoom',
    });
    const liftingSpeed = await WebApi(session).Dictionary.getOne({
        dict: 'liftingSpeed',
    });
    return {
        props: {
            categories,
            measureUnit: measureUnit.measureUnit || [],
            machineRoom: machineRoom.machineRoom || [],
            liftingSpeed: liftingSpeed.liftingSpeed || [],
            carryingCapacity: carryingCapacity.carryingCapacity || [],
            session: await getSession(context),
            company: company || [],
        },
    };
};
export default HomePage;

    async function __Next_Translate__getServerSideProps__190cb72dd40__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__190cb72dd40__ as getServerSideProps }
  